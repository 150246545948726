import React from 'react';
import { useHistory } from 'react-router-dom';

import { storeInfo } from '../../../helpers/data';
import AnimatedButton from '../../../shared/AnimatedButton';
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Locations() {
  const history = useHistory();
  const analytics = getAnalytics();

  const handleViewStoreButton = (id: any) => {
    history.push(`/store/${id}`);
    logEvent(analytics, `landing_page_navigate_store_${id}`);
  }

  function dispensaryType(location: any) { 
    if (location.menus.length > 1) {
      return "[" + location.menus[0].dispensaryType + " + " + location.menus[1].dispensaryType + "]"
    } else {
      return "[" + location.menus[0].dispensaryType + "]"
    } 
  }

  return (
    <section className="Locations">
      <div className="contentWrapper">
        <div className="content">
          <div className="storeImage">
            <div className="imageBackground" />
          </div>
          <div className="storesWrapper">
            { storeInfo.map((location) => (
              <div key={location.id} className="store">
                <div className="info">
                  <span className="name">
                    {location.name}
                  </span>
                  <span className="type">
                  { dispensaryType(location) }
                  </span>
                </div>
                <div className="controls">
                  <div className="map">
                    <span className="link">
                      <a href={location.googleLink}>
                        Contact
                      </a>
                    </span>
                  </div>
                  <div className="menu">
                    <AnimatedButton 
                      label="View Store"
                      className="viewStore"
                      theme="dark"
                      handler={() => handleViewStoreButton(location.id)}
                    />
                  </div>
                </div>
              </div>
            )) }

              <div key="ct001" className="store">
                <div className="info">
                  <span className="name">
                    STAMFORD, CT
                  </span>
                  <span className="type">
                   [Recreational]
                  </span>
                </div>
                <div className="controls">
                  <div className="map">
                    <span className="link">
                      
                    </span>
                  </div>
                  <div className="menu">
                    Coming Soon
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}
